@import '~bootstrap/scss/bootstrap.scss';
@import './assets/styles/scss/responsive.scss';
@import './assets/styles/scss/contact.scss';
@import './assets/styles/scss/footer.scss';
@import './assets/styles/scss/general.scss';
@import './assets/styles/scss/header.scss';
@import './assets/styles/scss/about/services.scss';
@import './assets/styles/scss/about/team.scss';
@import './assets/styles/scss/home/hero.scss';
@import './assets/styles/scss/home/homecta.scss';
@import './assets/styles/scss/home/tocontact.scss';
@import './assets/styles/scss/home/works.scss';
@import './assets/styles/scss/news/articles.scss';
@import './assets/styles/scss/single-work/gallery.scss';
@import './assets/styles/scss/single-work/stats.scss';
@import './assets/styles/scss/single-work/related.scss';
