/* toContact component styling */
#toContactSection{
    padding: 90px 0px 115px 0px;
    text-align: center;
    position: relative;
    h1{
      line-height: 1.6;
      font-weight: 500;
      margin-bottom: 50px;
    }
    a{
      color: var(--text);
      font-size: .95rem;
      font-weight: 400;
      padding: 17px 17px 17px 25px;
      border: 1px solid #ccc;
      transition: all .3s ease-in-out;
    }
    a:hover{
      background: #fff;
      border: 1px solid #fff;
      transition: all .3s ease-in-out;
    }
}