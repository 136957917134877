/* Responsive Design */
@media (max-width: 1300px){
    .container-fluid{
      padding: 0 25px;
    }
    .header{
      padding-top: 35px;
    }
  }
  @media (max-width: 1200px){
    .container-fluid{
      padding: 0 25px;
    }
    .header{
      padding-top: 35px;
    }
    #heroSection{
      height: 55vh !important;
    }
    h1.section-big-title {
      font-size: 1.75rem;
    }
    #singleNews{
      .single-news-right{
        margin-top: 30px;
      }
    }
  }
  @media (max-width: 992px){
    #heroSection{
      h1{
        font-size: 2rem;
        letter-spacing: -1px;
      }
      .single-work-hero{
        .single-hero-button{
          display: none;
        }
        h1{
          line-height: 1;
        }
        .single-work-fact{
          margin-top: 40px;
          h6{
            line-height: 1;
          }
          ul{
            li{
              font-size: .9rem;
              line-height: 1.7;
            }
          }
        }
      }
    }
    #singleStatsSection{
      .single-stats-title{
        max-width: 500px;
      }
    }
    #portfolioSection{
      padding: 50px 0px 25px 0px;
      .portfolio-main-title{
        margin-bottom: 25px;
      }
      .portfolio-filters{
        .portfolio-nav{
          justify-content: flex-start;
          a{
            margin-left: 0px;
            margin-right: 20px;
            font-size: .85rem;
            padding: 10px 16px;
          }
        }
      }
    }
    #sectionFooter{
      .social-footer-menu{
        ul{
          text-align: right;
          margin-bottom: 5px;
          li{
            margin-left: 10px;
            margin-right: 0px;
            a{
              font-size: .9rem;
            }
          }
        }
        p{
          text-align: right;
          font-size: .8rem;
        }
      }
    }
  }
  @media (max-width: 768px){
    .container-fluid{
      padding: 0 30px;
    }
    .container{
      padding: 0 30px;
    }
    h1.section-big-title{
      font-size: 1.25rem;
      letter-spacing: -.5px;
    }
    .header{
      padding-top: 35px;
      .logo{
        a{
          font-size: 17px;
        }
      }
    }
    #homeCtaSection{
      padding-bottom: 50px;
      .row{
        margin-top: 50px;
        h1{
          margin-bottom: 15px;
        }
        ul{
          margin-top: 15px;
          margin-bottom: 20px;
          li{
            font-size: .9rem;
          }
        }
        p{
          font-size: .9rem;
        }
      }
    }
    #portfolioSection{
      padding: 50px 0px 25px 0px;
      .works-wrapper{
        margin: 0 -15px !important;
      }
      .portfolio-main-title{
        margin-bottom: 25px;
      }
      .portfolio-filters{
        .portfolio-nav{
          justify-content: flex-start;
          a{
            margin-left: 0px;
            margin-right: 20px;
            font-size: .85rem;
            padding: 10px 16px;
          }
        }
      }
      .portfolio-box{
        padding: 0 15px;
        .portfolio-image{
          .portfolio-text{
            h3{
              font-size: 1rem;
            }
          }
        }
      }
    }
    #toContactSection{
      padding: 60px 0px 65px 0px;
      h1{
        margin-bottom: 35px;
      }
      a{
        font-size: .8rem;
        padding: 10px 10px 10px 18px;
      }
    }
    #singleGallerySection{
      padding: 50px 0px 25px 0px;
      .row{
        margin: 0 -15px;
        .col-12{
          padding-left: 15px;
          padding-right: 15px;
          img{
            margin-bottom: 25px;
          }
        }
      }
    }
    #singleStatsSection{
      padding: 40px 0px;
      h1{
        margin-bottom: 10px;
      }
      p{
        font-size: .9rem;
        line-height: 1.6;
      }
      .single-stat-box{
        padding-left: 15px;
        margin-bottom: 0px;
        h3{
          margin-bottom: 10px;
          font-size: 1.4rem;
          letter-spacing: -.5px;
        }
        h6{
          margin-bottom: 5px;
        }
        p{
          font-size: .85rem;
          line-height: 1.45;
          margin-top: 5px;
        }
      }
    }
    #singleRelatedSection{
      padding: 40px 0px 25px 0px;
      h1{
        margin-bottom: 25px;
      }
      .row{
        margin: 0 -15px;
        .portfolio-box{
          padding-left: 15px;
          padding-right: 15px;
          .portfolio-image{
            .portfolio-text{
              h3{
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
    #sectionServices{
      padding: 50px 0px 20px 0px;
      .container{
        .services-button{
          margin-top: 20px;
          padding-bottom: 25px;
          a{
            padding: 10px 10px 10px 18px;
            font-size: .8rem;
          }
        }
        .row{
          .service-box{
            margin-top: 25px;
            h6{
              margin-top: 13px;
              margin-bottom: 7px;
            }
            p{
              font-size: .85rem;
            }
          }
        }
      }
    }
    #sectionTeam{
      padding: 60px 0px 30px 0px;
      .row{
        margin: 0 -15px;
        h1{
          padding-left: 0px;
        }
        p{
          font-size: .9rem;
          line-height: 1.6;
          margin-bottom: 40px;
          padding-left: 0px;
          br{
            display: none;
          }
        }
        .team-box{
          margin-bottom: 25px;
          padding-left: 15px;
          padding-right: 15px;
          h6{
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: .9rem;
          }
          h5{
            font-size: 1rem;
            margin-bottom: 7px;
          }
        }
      }
    }
    #sectionArticles{
      padding: 50px 0 25px 0;
      .row{
        .articles-box{
          margin-bottom: 20px;
          h6{
            margin-top: 15px;
            margin-bottom: 5px;
          }
          h5{
            font-size: 1rem;
          }
        }
      }
    }
    #singleNews{
      .container{
        .single-news-left{
          .tags{
            span{
              display: inline-block;
              margin-bottom: 10px;
            }
          }
          blockquote{
            padding-left: 20px;
            p{
              font-size: 1rem;
              margin-bottom: 10px !important;
            }
            cite{
              font-size: .8rem;
            }
          }
        }
      }
    }
    #sectionArticlesNav{
      .news-nav-box{
        padding: 0px 30px;
        h3{
          font-size: 1.3rem;
        }
      }
    }
    #sectionArticlesNav .news-next, #sectionArticlesNav .news-prev{
      min-height: 200px;
    }
    #sectionContact{
      padding-bottom: 75px;
      .contact-left{
        margin-bottom: 30px;
        ul{
          margin-top: 20px;
        }
        h2{
          font-size: 1.5rem;
          font-weight: 400;
          margin-bottom: 24px;
        }
        h6{
          margin-bottom: 0px;
          &.phone{
            margin-top: 8px;
          }
        }
        h5{
          margin-bottom: 15px;
        }
        ul{
          text-align: left;
          margin-bottom: 10px;
          li{
            margin-left: 0px;
            margin-right: 20px;
          }
        }
        p{
          text-align: left;
        }
      }
    }
    #sectionFooter{
      .footer-contact-box{
        h6{
          margin-bottom: 0px;
          font-size: .9rem;
          &.phone{
            margin-top: 8px;
          }
        }
        h5{
          margin-bottom: 15px;
          font-size: .95rem;
          margin-top: 5px;
        }
      }
      .footer-address-box{
        margin-top: 0px;
      }
      .footer-logo{
        h3{
          margin-bottom: 20px;
          font-size: 18px;
        }
      }
    }
  }
  
  @media (max-width: 767px){
    .header{
      padding-top: 25px;
      .main-menu{
        margin-top: 15px;
        justify-content: flex-start;
        ul{
          li{
            text-align: left;
            a{
              margin: 0 25px 0 0;
            }
          }
        }
      }
    }
    #heroSection{
      height: 70vh !important;
      padding-top: 65px;
      padding-right: 50px;
      h1{
        font-size: 1.5rem;
        line-height: 1.5;
        letter-spacing: -.5px;
        br{
          display: block;
        }
      }
      .hero-button{
        margin-top: 25px;
        a{
          font-size: .8rem;
          font-weight: 400;
          padding: 10px 10px 10px 18px;
        }
      }
      p{
        font-size: .9rem;
        line-height: 1.6;
        padding-right: 20px;
        br{
          display: none;
        }
      }
      .article-meta{
        p{
          padding-right: 0px;
        }
      }
      .single-work-hero{
        .single-hero-button{
          display: none;
        }
        h1{
          line-height: 1;
        }
        .single-work-fact{
          margin-top: 20px;
          h6{
            line-height: 1;
          }
          ul{
            li{
              font-size: .9rem;
              line-height: 1.7;
            }
          }
        }
      }
    }
    #sectionFooter{
      .footer-address-box{
        margin-top: 10px;
      }
      .social-footer-menu{
        margin-top: 20px;
        ul{
          text-align: right;
          margin-bottom: 5px;
          li{
            margin-left: 0px;
            margin-right: 5px;
            a{
              font-size: .9rem;
            }
          }
        }
        p{
          text-align: left;
          font-size: .8rem;
        }
      }
    }
  }

  @media (max-width: 500px){
    #heroSection{
      padding-right: 0px;
      h1{
        padding-right: 50px;
        br{
          display: none;
        }
      }
      &.short{
        height: 65vh !important;
        padding-top: 75px;
      }
    }
  }
  
  @media (max-width: 360px){
    .container-fluid{
      padding: 0 15px;
    }
    .container{
      padding: 0 15px;
    }
    #heroSection{
      height: 95vh !important;
    }
    .short{
      height: 90vh !important;
    }
    #portfolioSection{
      .portfolio-filters{
        .portfolio-nav{
          a{
            font-size: .85rem;
            padding: 8px 12px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  
.partners-image {
  width: 10rem;
  margin: 2rem;
}

@media (max-width: 500px){
  .partners-image {
    width: 5rem;
    margin: 0rem!important;
  }
}