/* Single Stats Section styling */
#singleStatsSection{
    padding: 110px 0px 90px 0px;
    text-align: left;
    h1{
      margin-top: 2px;
      margin-bottom: 20px;
    }
    p{
      margin-top: 0px;
      font-size: 1.1rem;
      line-height: 1.65;
    }
    .single-stat-box{
      padding-left: 50px;
      margin-top: 10px;
      margin-bottom: 20px;
      h3{
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 15px;
        color: var(--black-text);
        span{
          font-size: .9rem;
          color: var(--grey-text);
          margin-left: 3px;
          font-weight: 400;
        }
      }
      h6{
        font-size: .9rem;
        color: var(--light-text);
        margin-bottom: 10px;
        font-weight: 400;
      }
      p{
        font-size: 1rem;
        line-height: 1.6;
        margin-top: 10px;
      }
    }
}