/* Header styling */
.header{
    padding: 16px 0px 16px 0px;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    .site-logo{
        display: flex;
        align-items: center;
        a{
            color: var(--text-gray);
            font-size: 19px;
            font-weight: 500;
            letter-spacing: 0px;
        }
    }
    .main-menu{
        display: flex;
        justify-content: flex-end;
        ul{
            display: flex;
            align-items: center;
            list-style: none;
            padding-left: 0px;
            margin-bottom: 0;
        li{
            display: inline-block;
            a{
                color: var(--text-gray);
                font-size: .96rem;
                margin: 0 0 0 30px;
                font-weight: 400;
                border-bottom: 3px solid transparent;
                transition: all .3s ease-in-out;
                &.active{
                    color: var(--black-text);
                    border-bottom: 3px solid var(--accent);
                }
                &:hover{
                    color: var(--black-text);
                    border-bottom: 3px solid var(--accent);
                    transition: all .3s ease-in-out;
                }
            }
        }
    }
}
    .social-menu{
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        ul{
            margin-bottom: 0;
            li{
            display: inline-block;
                a{
                    color: var(--black-text);
                    font-size: 16px;
                    margin: 0 10px;
                    letter-spacing: 0.5px;
                    font-weight: 400;
                }
            }
        }
    }   
}