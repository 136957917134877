/* Works component styling */
#portfolioSection{
    padding: 125px 0px 100px 0px;
    border-top: 1px solid #e8e8e8;
    .portfolio-main-title{
      margin-bottom: 50px;
      h1{
        margin-bottom: 0px;
      }
    }
    .portfolio-filters{
      margin-bottom: 50px;
      .portfolio-nav{
        padding-bottom: 6px;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        a{
          display: inline-block;
          color: var(--text);
          font-size: .95rem;
          font-weight: 400;
          text-transform: capitalize;
          padding: 13px 18px;
          margin-left: 20px;
          border: 1px solid #dbdbdb;
          transition: all .3s ease-in-out;
          cursor: pointer;
          &.active{
            border: 1px solid var(--accent);
            background: var(--accent);
            color: var(--text);
          }
          &:hover{
            background: var(--accent);
            border: 1px solid transparent;
            transition: all .3s ease-in-out;
          }
        }
      }
    }
    .works-wrapper{
      margin: 0 -20px;
    }
    .portfolio-box{
      transition: all .3s ease-in-out;
      padding: 0 20px;
      .portfolio-image{
        .work-img-wrapper{
          overflow: hidden;
          img{
            max-width: 100%;
            transform: scale(1.15);
            transition: all .3s ease-in-out;
          }
        }
        .portfolio-text{
          margin-top: 20px;
          margin-bottom: 45px;
          display: flex;
          justify-content: space-between;
          h3{
            font-size: 1.1rem;
            font-weight: 400;
            margin-bottom: 0;
            color: var(--black-text);
            opacity: 1;
            transition: all .3s ease-in-out;
          }
          h6{
            font-size: 1rem;
            font-weight: 400;
            color: var(--light-text);
            margin-bottom: 0;
            text-transform: capitalize;
            opacity: 1;
            transition: all .3s ease-in-out;
          }
        }
        &:hover{
          .work-img-wrapper{
            img{
              max-width: 100%;
              transform: scale(1.5);
              transition: all .3s ease-in-out;
            }
          }
          .portfolio-text{
            h3{
              opacity: .2 !important;
              transition: all .3s ease-in-out;
            }
            h6{
              color: var(--black-text);
              opacity: .2 !important;
              transition: all .3s ease-in-out;
            }
          }
        }
      }
      &.portfolio-hidden{
        opacity: .35 !important;
        transition: all .3s ease-in-out;
        position: relative;
        .portfolio-text{
          opacity: 0 !important;
        }
        .cancel-anchor{
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: transparent;
          z-index: 999;
        }
      }
    }
}