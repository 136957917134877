/* Team styling */
#sectionTeam{
    padding: 110px 0 70px 0;
    border-top: 1px solid #e8e8e8;
    .row{
      margin: 0 -20px;
      h1{
        padding-left: 5px;
        margin-bottom: 10px;
      }
      p{
        margin-bottom: 60px;
        padding-left: 5px;
      }
      .team-box{
        padding: 0 20px;
        text-align: center;
        margin-bottom: 40px;
        img{
          max-width: 100%;
        }
        h5{
          font-size: 1.1rem;
          font-weight: 400;
          color: var(--black-text);
          margin-bottom: 7px;
        }
        h6{
          margin-top: 15px;
          font-size: .9rem;
          color: var(--light-text);
          line-height: 24px;
          font-weight: 400;
          margin-bottom: 7px;
        }
        .team-box-social{
          a{
            margin: 0 5px;
            color: var(--light-text);
            transition: all .3s ease-in-out;
            i{
              font-size: 14px;
            }
            &:hover{
              color: var(--black-text);
              transition: all .3s ease-in-out;
            }
          }
        }
      }
    }
}