/* Articles styling */
#sectionArticles{
    padding: 125px 0 75px 0;
    border-top: 1px solid #e8e8e8;
    .row{
      margin: 0 -20px;
      .articles-box{
        padding: 0 20px;
        margin-bottom: 50px;
        text-align: center;
        .article-image{
          .article-img-wrapper{
            overflow: hidden;
            img{
              max-width: 100%;
              transform: scale(1);
              transition: all .3s ease-in-out;
            }
          }
          .articles-text{
            h5{
              font-size: 1.1rem;
              font-weight: 400;
              color: var(--black-text);
              line-height: 1.5;
              max-width: 75%;
              margin: 0 auto 10px auto;
              transition: all .3s ease-in-out;
            }
            h6{
              margin-top: 20px;
              font-size: .9rem;
              color: var(--light-text);
              line-height: 24px;
              font-weight: 400;
              transition: all .3s ease-in-out;
            }
          }
          &:hover{
            .article-img-wrapper{
              img{
                max-width: 100%;
                transform: scale(1.15);
                transition: all .3s ease-in-out;
              }
            }
            .articles-text{
              h5{
                opacity: .2 !important;
                transition: all .3s ease-in-out;
              }
              h6{
                color: var(--black-text);
                opacity: .2 !important;
                transition: all .3s ease-in-out;
              }
            }
          }
        }
      }
    }
}