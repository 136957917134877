/* Single Work Gallery styling */
#singleGallerySection{
    padding: 125px 0px 75px 0px;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    .row{
      margin: 0 -35px;
      .col-12{
        padding-left: 35px;
        padding-right: 35px;
        transform: translateY(50px);
        opacity: 0;
        transition: all .2s ease-in-out;
        img{
          max-width: 100%;
          margin-bottom: 70px;
        }
      }
    }
  }